const ready = fn => {
  if (document.readyState !== 'loading') {
    if (typeof fn !== 'function') return fn

    if (document.readyState === 'complete' && checkReload()) return fn({ skipListeners: true })

    document.addEventListener('turbolinks:load', fn)
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

const checkReload = () => {
  if (document.currentScript.dataset?.turbolinksTrack === 'keep') return false

  return document.currentScript.dataset?.turbolinksTrack === 'reload' ||
    document.currentScript.src.includes('components')
}

export { ready }
