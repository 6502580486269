import '../styles/application'
import '../components'
import '../init/stimulus'
import '../init/turbolinks'
import '../init/ujs'
import '../init/futurism'
import 'channels'
import formsValidations from '../src/application/forms_validations'

window.formsValidations = formsValidations

formsValidations()

require.context('../images', true)

console.log('LOCAL COMPILE TEST 1')

/* eslint-disable */
Number.prototype.toCurrency = function (n = 2, x = 3, s = ' ', c = '.') {
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')'
  const num = this.toFixed(Math.max(0, ~~n))
  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + s)
}

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

if (!Array.prototype.last) {
  Array.prototype.last = function () {
    return this[this.length - 1]
  }
}

if (!Array.prototype.first) {
  Array.prototype.first = function () {
    return this && this[0]
  }
}
/* eslint-enable */
