import Turbolinks from 'turbolinks'

Turbolinks.start()

const removeLoader = () => {
  const serverLoader = document.getElementById('server_loader')
  if (!serverLoader) return afterRemoveLoader()

  const logoSpinner = serverLoader.querySelector('div')

  if (serverLoader.classList.contains('is-paused')) {
    serverLoader.classList.remove('is-paused')
    setTimeout(() => logoSpinner?.remove(), 5000)
  } else {
    serverLoader.style.opacity = '0.25'
    logoSpinner?.remove()
    // serverLoader.remove()
  }

  if (serverLoader.classList.contains('clicked')) {
    serverLoader.classList.remove('clicked')
    logoSpinner?.remove()
  }

  afterRemoveLoader()
}

const afterRemoveLoader = () => {
  const mainPage = document.getElementById('main_content')
  mainPage.classList.remove('turn-start')
  mainPage.classList.add('unturned')

  setTimeout(pageFinishTurn, 0)
}

const startFade = () => {
  const serverLoader = document.getElementById('server_loader')

  if (!serverLoader) return false
  serverLoader.querySelector('div')?.remove()

  if (!serverLoader.classList.contains('is-paused')) {
    serverLoader.classList.add('is-paused')
    serverLoader.classList.add('clicked')
  }

  pageStartTurn()
}

const pageStartTurn = () => {
  const mainPage = document.getElementById('main_content')

  mainPage.classList.add('turn-start')
}

const pageFinishTurn = () => {
  const mainPage = document.getElementById('main_content')

  mainPage.classList.remove('unturned')
}

const reloadWithTurbolinks = () => {
  let scrollPosition

  function reload () {
    scrollPosition = [window.scrollX, window.scrollY]
    Turbolinks.visit(window.location.toString(), { action: 'replace' })
  }

  window.addEventListener('turbolinks:load', function () {
    if (scrollPosition) {
      window.scrollTo.apply(window, scrollPosition)
      scrollPosition = null
    }
  })

  return reload
}

window.addEventListener('popstate', () => requestAnimationFrame(reloadWithTurbolinks))
document.addEventListener('turbolinks:click', startFade)
document.addEventListener('turbolinks:load', removeLoader)
// // document.addEventListener('turbolinks:before-render', removeLoader)
// document.addEventListener('turbolinks:render', removeLoader)

window.onerror = (message, file, line, col, error) => {
  if (error.message === 'MtPopUpList is not defined') return true
}
