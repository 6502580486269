import './component.sass'
import { ready } from 'utils'
import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.css'

ready(() => {
  const gallery = document.getElementById('gallery')
  if (!gallery) return

  new Viewer(gallery,
    {
      toolbar: {
        zoomIn: true,
        zoomOut: true,
        oneToOne: true,
        reset: true,
        prev: false,
        play: {
          show: false,
          size: 'large',
        },
        next: false,
        rotateLeft: true,
        rotateRight: true,
        flipHorizontal: false,
        flipVertical: false
      },
      navbar: false
    }
  )
})
