import CableReady from 'cable_ready'
import { createChannel } from '../init/cable'
import { ready } from '../src/utils'

ready(() => {
  createChannel('TasksChannel', {
    connected () {
      console.log('Connected to TasksChannel')
    },

    received (data) {
      console.log('Received', data)
      if (data.cableReady) CableReady.perform(data.operations)
    },

    disconnected () {
      console.log('Disconnected to TasksChannel')
    }
  })
})
