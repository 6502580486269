import debounce from 'lodash-es/debounce'

export default () => {
  const forms = document.querySelectorAll('.needs-validation')
  const validations = sessionStorage.validations ? JSON.parse(sessionStorage.validations) : { errors: {}, warnings: {} }

  Array.prototype.slice.call(forms).forEach(form => {
    form.addEventListener('submit', event => {
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      }

      form.classList.add('was-validated')
    }, false)

    for (const el of [...form.elements]) {
      if (el.value?.length > 0 || (el.type === 'checkbox' && el.checked))
        el.classList.remove('pristine')

      el.addEventListener('keyup', _e => makeDirty(el), false)
      el.addEventListener('change', _e => makeDirty(el), false)
    }
    form.addEventListener('keyup', debounce(event => validateCheck(event, form, false), false), 500)
    form.addEventListener('change', event => validateCheck(event, form, true), false)
  })

  const validateCheck = (event, form, each = false) => {
    form.getElementsByClassName('form_errors')[0].innerHTML = ''
    const validateType = form.dataset.validate
    const validators = require(`./${validateType}`).default
    const elements = each ? [...form.elements] : [event.target]

    elements.forEach(el => {
      if ((validations.errors[validateType] && validations.errors[validateType][el.id]) ||
        !el.classList.contains('pristine'))
        checkAppValidity(validateType, el, validators)
    })
    const submits = form.querySelectorAll('button[type="submit"]')
    const addButtons = form.querySelectorAll('a[role="button"]')

    const inters = intersections(Object.keys(validators), [...form.getElementsByClassName('pristine')].map(el => {
      if (el.required) return el.id
    }))
    const invalid = (validations.errors[validateType] && Object.keys(validations.errors[validateType]).length !== 0) ||
      inters.length > 0 || [...form.elements].filter(el => { return el.classList.contains('is-invalid') }).length !== 0

    submits.forEach(submit => {
      submit.disabled = invalid
    })
    if (invalid) {
      addButtons.forEach(add => add.classList.add('disabled'))
    } else {
      addButtons.forEach(add => add.classList.remove('disabled'))
      delete validations.errors[validateType]
    }
  }

  const checkAppValidity = (validateType, element, validators) => {
    const newValidations = validations

    if (!Object.keys(validators).includes(element.id)) return newValidations
    if (element.value.length === 0 && newValidations.errors[validateType])
      delete newValidations.errors[validateType][element.id]

    newValidations.errors[validateType] = validations.errors[validateType] || {}
    newValidations.warnings[validateType] = validations.warnings[validateType] || {}

    for (const key of Object.keys(validators[element.id])) {
      const checker = validators[element.id][key]
      const aria = element.getAttribute('aria-describedby')
      const errorField = document.getElementById(aria) || document.createElement('div')
      errorField.id = aria

      if (checker.check(element)) {
        if (element.value.length === 0) return setNeutral(validateType, element, newValidations)
        setValid(validateType, errorField, element, newValidations)
      } else { setInvalid(validateType, errorField, element, checker.message, newValidations) }
    }

    const newValidationByType = { ...validations[validateType], ...newValidations[validateType] }
    sessionStorage.validations = JSON.stringify({ ...validations, ...newValidationByType })
    return newValidations
  }

  const makeDirty = el => el.classList.remove('pristine')

  const setInvalid = (type, errorField, element, message, newValidations) => {
    element.classList.add('is-invalid')
    element.classList.remove('is-valid')
    newValidations.errors[type][element.id] = message
    errorField.innerHTML = message
    errorField.classList.remove('hidden')
    errorField.classList.add('invalid-feedback')
    errorField.classList.remove('valid-feedback')
    errorField.classList.remove('fade_out')
    element.parentElement.appendChild(errorField)
    errorField.classList.add('fade_in')
  }

  const setValid = (type, errorField, element, newValidations) => {
    element.classList.add('is-valid')
    element.classList.remove('is-invalid')
    delete newValidations.errors[type][element.id]
    errorField.classList.remove('fade_in')
    errorField.classList.add('fade_out')
    errorField.classList.add('valid-feedback')
    errorField.classList.remove('invalid-feedback')
    errorField.classList.add('hidden')
  }

  const setNeutral = (type, element, newValidations) => {
    element.classList.remove('is-valid')
    element.classList.remove('is-invalid')
    delete newValidations.errors[type][element.id]
  }

  const intersections = (array1, array2) => {
    return array1.filter(value => array2.indexOf(value) !== -1)
  }
}
