export default {
  user_login: {
    isPresent: {
      check: element => element.value.length > 0,
      message: 'Required'
    }
  },
  user_password: {
    isPresent: {
      check: element => element.value.length > 0,
      message: 'Required'
    }
  }
}
