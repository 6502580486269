import { createConsumer } from '@rails/actioncable'

let consumer

const createCable = () => {
  if (!consumer)
    consumer = createConsumer()

  return consumer
}

const createChannel = (...args) => {
  const consumer = createCable()
  consumer.subscriptions.create(...args)
}

export { createChannel, createCable }
